import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
// import { deleteRPCUrl, getRPCUrls, rpcStatusUpdate } from "../../request/rpc";
// import { RPCListResponse } from "./rpc.interface";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
// import { UseCheckPermissions } from "../../utils/useCheckPermissions";
// import NoPermisisons from "../../components/Base/NoPermissions";
import {
  deleteReceiverWallet,
  getReceiverWallets,
} from "../../request/receiver-wallet";

const ReceiverWalletsListing = () => {
  const [receiverWallets, setReceiverWallets] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      const response = await getReceiverWallets();
      if (response?.status === 200) {
        console.log("success", response);
        setReceiverWallets(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const updateStatus = async (rpcUrl: RPCListResponse) => {
  //   try {
  //     const isActive = rpcUrl?.isActive == 1 ? 0 : 1;
  //     const Id = rpcUrl?.id;
  //     const response = await rpcStatusUpdate(isActive, Id);
  //     fetchData();
  //   } catch (e) {}
  // };

  const deleteWallet = async (receiverWallet: any) => {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const response = await deleteReceiverWallet(receiverWallet?.id);
      if (response?.status === 200) {
        dispatch(alertSuccess("Deleted Successfully"));
        fetchData();
      } else {
        dispatch(alertFailure("Error While Deleting Wallet"));
      }
    } catch (e) {
      dispatch(alertFailure("Error While Deleting Wallet"));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // chain integration
  const networkName: any = {
    eth: "ETH",
    bsc: "BSC",
    polygon: "MATIC",
    arbitrum: "ARB",
    avalanche: "AVAX",
    solana: "SOL",
    coredao: "CORE",
    zksync: "zkSync",
    base: "BASE",
    linea: "LINEA",
    blast: "BLAST",
    bera: "BERA",
    sonic: "SONIC",
  };

  // It is a new functionality and we don't have it's permissions added yet so we are commenting all the permissions right now
  // const isViewReceiverWallets = UseCheckPermissions("VIEW_RECEIVER_WALLETS");
  // const isEditReceiverWallets = UseCheckPermissions("EDIT_RECEIVER_WALLETS");

  return (
    <DefaultLayout>
      {/* {isViewReceiverWallets ? ( */}
      <>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "30px" }}
          onClick={() => {
            history.push("add-receiver-wallets");
          }}
          // disabled={!isEditReceiverWallets}
        >
          Add Wallet
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/*<TableCell>Id</TableCell>*/}
                <TableCell>Network</TableCell>
                <TableCell>Wallet</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receiverWallets?.map((receiver: any) => (
                <TableRow key={receiver.id}>
                  {/*<TableCell>{rpcUrl.id}</TableCell>*/}
                  <TableCell>{networkName[receiver.network]}</TableCell>
                  <TableCell>{receiver.wallet}</TableCell>
                  <TableCell>
                    <Button
                      // disabled={!isEditReceiverWallets}
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        history.push(`edit-receiver-wallets/${receiver?.id}`);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      // disabled={!isEditReceiverWallets}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        deleteWallet(receiver);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      {/* ) : (
        <NoPermisisons />
      )} */}
    </DefaultLayout>
  );
};

export default ReceiverWalletsListing;
