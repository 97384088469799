import BigNumber from 'bignumber.js';
import { ethers } from "ethers";
import _ from 'lodash';
import {ADMIN_URL_PREFIX, API_URL_PREFIX, ETHERSCAN_BASE_URL, IMAGE_URL_PREFIX, NETWORK_AVAILABLE} from "../constants";
import axios from "axios";
import { PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js';
import { BaseRequest } from '../request/Request';
import { PoolMetadata, RefundCurrency, TokenInfo } from '../types/solana';
import * as anchor from "@project-serum/anchor";
import { SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_PROGRAM_ID } from '../context/solana/constants';

// const ETHERSCAN_BASE_URL: any = {
//   '1': 'https://etherscan.io/address',
//   '4': 'https://rinkeby.etherscan.io/address',
//   '5': 'https://goerli.etherscan.io/address',
//   '97': 'https://testnet.bscscan.com/address',
// };

export function formatPrecisionAmount(amount: any, precision: number = 18): string {
  const rawValue = new BigNumber(`${amount}`).toFixed(precision);
  return (amount && parseFloat(amount) !== Infinity) ? new BigNumber(rawValue).toFormat() : '0';
}

export const routeWithPrefix = (prefix = ADMIN_URL_PREFIX, url = '') => {
  const truncateUrl = _.trim(url, '/');
  return `/${prefix}/${truncateUrl}`;
};

export const adminRoute = (url = '') => {
  const truncateUrl = _.trim(url, '/');
  const resUrl = `/${ADMIN_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};

export const publicRoute = (url = '') => {
  const truncateUrl = _.trim(url, '/');
  const resUrl = `/${truncateUrl}`;
  return resUrl;
};

export const checkIsAdminRoute = (pathname: string) => {
  return (pathname.indexOf(`/${ADMIN_URL_PREFIX}`) !== -1) || (pathname === '/dashboard/login');
};

export const checkIsLoginRoute = (pathname: string) => {
  return pathname.indexOf(`/login`) !== -1;
};

export const checkIsInvestorRoute = (pathname: string) => {
  return false;
  // return (pathname.indexOf(`/buy-token`) !== -1) ||  (pathname === '/login');
};

export const apiRoute = (url = '') => {
  const truncateUrl = _.trim(url, '/');
  const resUrl = `/${API_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};

export const imageRoute = (url = '') => {
  const truncateUrl = _.trim(url, '/');
  const resUrl = `${process.env.REACT_APP_API_BASE_URL || ''}/${IMAGE_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};

// export const etherscanAddressRoute = (address = '', poolDetail: any = null) => {
//   return etherscanRoute(`address/${address}`, poolDetail);
// };
//
// export const etherscanTransactionRoute = (address = '', poolDetail: any = null) => {
//   return etherscanRoute(`tx/${address}`, poolDetail);
// };

// chain integration
export const etherscanRoute = (address = '', poolDetail: any = null) => {
  let network = '';
  if (poolDetail) {
    if (poolDetail.network_available === NETWORK_AVAILABLE.BSC) {
      network = process.env.REACT_APP_BSC_NETWORK_ID + '';
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.POLYGON){
      network = process.env.REACT_APP_POLYGON_NETWORK_ID + '';
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.AVALANCHE){
      network = process.env.REACT_APP_AVALANCHE_NETWORK_ID + '';
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.ARBITRUM){
      network = process.env.REACT_APP_ARBITRUM_NETWORK_ID + '';
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.BASE) {
      network = process.env.REACT_APP_BASE_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.DAO) {
      network = process.env.REACT_APP_DAO_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.OKX) {
      network = process.env.REACT_APP_OKX_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.ZKSYNC) {
      network = process.env.REACT_APP_ZKSYNC_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.LINEA) {
      network = process.env.REACT_APP_LINEA_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.BLAST) {
      network = process.env.REACT_APP_BLAST_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.BERA) {
      network = process.env.REACT_APP_BERA_NETWORK_ID + "";
    } else if (poolDetail.network_available === NETWORK_AVAILABLE.SONIC) {
      network = process.env.REACT_APP_SONIC_NETWORK_ID + "";
    } 
    else if (poolDetail.network_available === NETWORK_AVAILABLE.SOLANA) {
      network = "sol";
    } else {
      network = process.env.REACT_APP_ETH_NETWORK_ID + '';
    }
  }

  const networkId = network || localStorage.getItem('NETWORK_ID') || process.env.REACT_APP_ETH_NETWORK_ID || '1';
  const baseUrl = ETHERSCAN_BASE_URL[networkId];
  const truncateUrl = _.trim(address, '/');
  const resUrl = `${baseUrl}/${truncateUrl}`;
  return resUrl;
};

export const getTransactionRowType = (transaction: any) => {
  if (transaction?.type === 'Refund') {
    return 'Refund';
  }
  if (transaction?.type === 'TokenClaimed') {
    return 'Claim';
  }
  return 'Buy';
};

export const getETHPrices = async () => {
  // To use:
  // useEffect(() => {
  //   getETHPrices().then((resPrices: any) => {
  //   });
  // }, []);

  return await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
    .then(function (response) {
      let resData = JSON.parse(JSON.stringify(response));
      resData = (resData && resData.data) || {};
      return (resData && resData.ethereum && resData.ethereum.usd) || 0;
    })
    .catch(function (error) {
      console.log(error);
    });
};


export const weiToEth = (value: any) => {
  try {
    const amountInWei = ethers.BigNumber.from(value);
    const convertedValue = ethers.utils.formatEther(amountInWei);
    return convertedValue;
  } catch (error) {
    return '0'; 
  }
};

export const ethtoWei = (value : any) => {
  const amount = String(value);
  const weiValue = ethers.utils.parseEther(amount);
  return weiValue;
}

export function formatDecimalNumber(value : any) {
  const stringValue = value?.toString();
  const decimalIndex = stringValue?.indexOf('.');
  
  if (decimalIndex === -1) {
    return value;
  }

  const decimalPlaces = stringValue.length - decimalIndex - 1;

  if (decimalPlaces > 3) {
    return stringValue?.substring(0, decimalIndex + 4);
  }

  return value;
}

export function isValidTonAddress(source: string): boolean {
  if (source.length !== 48) {
    return false;
  }

  if (!/[A-Za-z0-9+/_-]+/.test(source)) {
    return false;
  }

  return true;
}

export function removeDuplicates<T>(arr: T[]): T[] {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

//Solana Functions

export const isTimeError = (errorMessage: string) => {
  const regex = new RegExp(
    `^Transaction was not confirmed in 30\\.00 seconds\\. It is unknown if it succeeded or failed\\. Check signature ([1-9A-HJ-NP-Za-km-z]{88}) using the Solana Explorer or CLI tools\\.$`
  );
  const resp: any = regex.exec(errorMessage);
  if (resp === null) {
    return {
      isTimeoutError: false,
      sig: "",
    };
  } else {
    return {
      isTimeoutError: true,
      sig: resp[1],
    };
  }
};

export const solanaApiRoute = (url = "") => {
  const truncateUrl = _.trim(url, "/");
  const resUrl = `/${truncateUrl}`;
  return resUrl;
};

export const SendSolanaTransaction = async (
  tx: anchor.web3.Transaction,
  signers?: anchor.web3.Keypair[],
  confirmOptions?: anchor.web3.SendOptions
) => {
  if (signers !== undefined) {
    tx.sign(...signers);
  }
  try {
    const sig = await window.solana.signAndSendTransaction(tx, confirmOptions);
    const baseRequest = new BaseRequest();
    let url = solanaApiRoute(`/user/solana/check-signature-status`);
    const body = {
      sig: sig.signature,
      block_hash: tx.recentBlockhash,
    };
    let response: any = null;
    const retryInterval = 40000;

    while (true) {
      try {
        response = await baseRequest.post(url, body);
        if (response.ok) {
          break;
        }
      } catch (error) {
        console.log("Failed to get response. Retrying in 40 seconds...");
      }
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
    }

    const result = await response.json();
    const data = result.data;
    if (data === "confirmed") {
      return sig;
    } else {
      return "Transactional Failed";
    }
  } catch (error: any) {
    return error;
  }
};

export const SendSolanaTransactionForTime = async (
  tx: anchor.web3.Transaction,
  signers?: anchor.web3.Signer[],
  confirmOptions?: anchor.web3.SendOptions
) => {
  try {
    if (signers !== undefined) {
      tx.sign(...signers);
    }
    const sig = await window.solana.signAndSendTransaction(tx, confirmOptions);
    const baseRequest = new BaseRequest();
    let url = solanaApiRoute(`/user/solana/check-signature-status`);
    const body = {
      sig: sig.signature,
      block_hash: tx.recentBlockhash,
    };
    let response: any = null;
    let data;
    const initialDelay = 8000;
    const retryInterval = 2000;

    await new Promise((resolve) => setTimeout(resolve, initialDelay));

    while (true) {
      try {
        response = await baseRequest.post(url, body);
        const result = await response.json();
        data = result.data;
        if (data === "finalized" || data === "failed") {
          break;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 524) {
          console.log("🚀 ~ 524 timeout error, retrying...");
        }
      }
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
    }
    if (data === "finalized") {
      return sig;
    } else if (data === "failed") {
      throw new Error("Transaction Failed");
    } else {
      throw new Error("Unexpected response data");
    }
  } catch (error: any) {
    throw new Error(error?.message);
  }
};

export const getTokenInfo = async (account: PublicKey) => {
  const baseRequest = new BaseRequest();
  let url = solanaApiRoute(`/user/solana-token-account/${account}`);
  const response = (await baseRequest.get(url)) as any;
  const result = await response.json();
  const data: TokenInfo = result.data;
  return data;
};

export const getPoolCount = async () => {
  const baseRequest = new BaseRequest();
  let url = solanaApiRoute(`/user/get-pool-count`);
  const response = (await baseRequest.get(url)) as any;
  const result = await response.json();
  const data: { count: number } = result.data;
  return data;
};

export const getPoolMetadata = async (id: number) => {
  const baseRequest = new BaseRequest();
  let url = solanaApiRoute(`/user/get-pool-metadata/${id}`);
  const response = (await baseRequest.get(url)) as any;
  const result = await response.json();
  const data: PoolMetadata = result.data;
  return data;
};

export const getPoolMetadataPda = async (id: number) => {
  const baseRequest = new BaseRequest();
  let url = solanaApiRoute(`/user/get-pool-metadata-pda/${id}`);
  const response = (await baseRequest.get(url)) as any;
  const result = await response.json();
  const data = result.data;
  return data;
};

export const getRefundCurrency = async (id: number) => {
  const baseRequest = new BaseRequest();
  let url = solanaApiRoute(`/user/get-refund-currency/${id}`);
  const response = (await baseRequest.get(url)) as any;
  const result = await response.json();
  const data: RefundCurrency = result.data;
  return data;
};

export const getOfferedCurrency = async (id: number) => {
  const baseRequest = new BaseRequest();
  let url = solanaApiRoute(`/user/get-offered-currency/${id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const convertToTransaction = async (obj: any) => {
  const transaction = new Transaction();
  transaction.feePayer = new PublicKey(obj.feePayer);
  for (var i = 0; i < obj.instructions.length; i++) {
    obj.instructions[i] = new TransactionInstruction({
      keys: obj.instructions[i].keys.map((key: any) => ({
        ...key,
        pubkey: new PublicKey(key.pubkey),
      })),
      programId: new PublicKey(obj.instructions[i].programId),
      data: Buffer.from(obj.instructions[i].data),
    });
  }
  transaction.add(...obj.instructions);
  transaction.recentBlockhash = obj.recentBlockhash;
  if (obj?.lastValidBlockHeight) {
    transaction.lastValidBlockHeight = obj?.lastValidBlockHeight + 2000;
  }
  // transaction.signers = obj.signers.map(signer => new PublicKey(signer)
  return transaction;
};

export const deriveTokenAccount = (owner: string, mint: string) => {
  const OWNER = new PublicKey(owner);
  const MINT = new PublicKey(mint);

  const [address] = PublicKey.findProgramAddressSync(
    [OWNER.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), MINT.toBuffer()],
    SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
  );

  return address;
};

export const validateJson = (input: any) => {
  try {
    JSON.parse(input);
    return true;
  } catch (error) {
    return false;
  }
};