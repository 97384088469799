import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import EnableKycForm from "./EnableKycForm";
import DisableClaimForm from "./DisableClaimForm";
import EnableSolanaFeatureForm from "./EnableSolanaFeatureForm";
import { getFlag } from "../../request/config";
import { FormControl, Switch, Tooltip } from "@material-ui/core";

const Config = () => {
  const [solnaFeatFlag, setSolanaFeatFlag] = useState(true);
  const [solanaFlag, setSolanaFlag] = useState("");
  const [isOpenEditPopupSolana, setIsOpenEditPopupSolana] =
    useState<boolean>(false);
  const [displaySoalnaLabel, setDisplaySolanaLabel] = useState(true);
  const [localSolanaFlag, setLocalSolanaFlag] = useState(true);
  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
      const solanaFlag = flags?.find((flag: any) => flag?.name === "solana");
      if (response?.status === 200) {
        setSolanaFlag(solanaFlag);
        setSolanaFeatFlag(solanaFlag.value);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  const handleSolanaSwitchChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    setLocalSolanaFlag(value);
    setIsOpenEditPopupSolana(true);
    setDisplaySolanaLabel(checked);
  };
  return (
    <DefaultLayout>
      <div>
        <EnableKycForm />
        <DisableClaimForm />
        <EnableSolanaFeatureForm
          fetchFlags={fetchFlags}
          solanaFlag={solanaFlag}
          isOpenEditPopupSolana={isOpenEditPopupSolana}
          setIsOpenEditPopupSolana={setIsOpenEditPopupSolana}
          solnaFeatFlag={solnaFeatFlag}
          setSolanaFeatFlag={setSolanaFeatFlag}
          displaySoalnaLabel={displaySoalnaLabel}
          localSolanaFlag={localSolanaFlag}
        />
        <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
          <Tooltip
            title={
              <p>
                This toggle when turned on will show Solana pools on userside
                and when off users will not see Solana pools or anything linked
                to Solana pools like phantom wallet.
              </p>
            }
          >
            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Switch
                onChange={(e) => handleSolanaSwitchChange(e)}
                checked={solnaFeatFlag ? true : false}
              />
              <h4
                style={{
                  marginRight: "10px",
                  marginTop: "5px",
                  fontSize: "14px",
                }}
              >
                Enable Solana Feature
              </h4>
            </span>
          </Tooltip>
        </FormControl>
      </div>
    </DefaultLayout>
  );
};

export default Config;
