import React from 'react'
import ConfirmDialog from '../../../components/Base/ConfirmDialog';

const TransferOwnershipForm = (props: any) => {
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    onConfirmTransfer,
    disableButton,
    address,
    poolDetail,
    loading,
  } = props;


  const handleSubmitPopup = () => {
    onConfirmTransfer();
  };

  return (
    <ConfirmDialog
      title={"Transfer Ownership Confirmation"}
      open={isOpenEditPopup}
      confirmLoading={disableButton}
      onConfirm={handleSubmitPopup}
      onCancel={() => {
        setIsOpenEditPopup(false);
      }}
      btnLoading={loading}
    >
      <p>You are about to transfer ownership of the selected pool</p>
      <div style={{ display: "flex", marginTop: "5px", alignItems: 'center' }}>
        <p style={{fontWeight: 'bold'}}>Selected Pool:</p>
        <p style={{ marginLeft: "10px" }}>
          {poolDetail?.title}
        </p>
      </div>
      <div style={{ display: "flex", marginTop: "5px", alignItems: 'center' }}>
        <p style={{fontWeight: 'bold'}}>Transfer to Wallet:</p>
        <p style={{ marginTop: "2px", marginLeft: "5px" }}>{address.wallet}</p>
      </div>
      <p>Please review the details carefully before proceeding</p>
    </ConfirmDialog>
  );
};

export default TransferOwnershipForm