import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";

export const getReceiverWallets = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/receiver-wallets`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const rpcStatusUpdate = async (isActive: number, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = (await baseRequest.patch(url, {
    isActive: isActive,
  })) as any;
  return await response?.json();
};

export const deleteRPCUrl = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};
export const addReceiverWallets = async (receiverWallet: { wallet: string; network: string }) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/receiver-wallets`);
  const response = (await baseRequest.post(url, receiverWallet)) as any;
  return await response?.json();
};

export const updateReceiverWallet = async (receiver: { wallet: string; network: string }, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/receiver-wallets/${Id}`);
  const response = (await baseRequest.patch(url, receiver)) as any;
  return await response?.json();
};

export const getReceiverWalletById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/receiver-wallets/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const deleteReceiverWallet = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/receiver-wallets/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};
