import React, { useCallback, useEffect, useState } from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import Select from "react-select";
import { getAllPools, setBlockUser } from "../../request/block-user";

function SetBlockUsersForm(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, setQuery, getUserListInfo } = props;

  const [pool, setPool] = useState<any>(0);
  const [wallet, setWallet] = useState("");
  const [loading, setLoading] = useState(false);
  const [availableLinkedPools, setAvailableLinkedPools] = useState<
    { value: number; label: string; campaignId: number }[]
  >([]);
  const [selectedLinkedPool, setSelectedLinkedPool] = useState<{
    value: number;
    label: string;
    campaignId: number;
  } | null>(null);

  const handleSubmitPopup = async () => {
    setLoading(true);
    try {
      setIsOpen(false);

      const res = await setBlockUser({
        walletAddress: wallet,
        campaign_id: pool.value,
      });
      if (res.status !== 200) {
        dispatch(alertFailure(res.message || "Something wrong"));
        return;
      }
      dispatch(alertSuccess("User Blocked Successfully"));
      setQuery("");
      getUserListInfo();
      setLoading(false);
    } catch (err) {
      console.log(err);
      dispatch(alertFailure("Something wrong"));
      setLoading(false);
    }
  };

  const fetchLinkedPools = useCallback(async () => {
    try {
      const { data } = await getAllPools();
      const formattedPools = data?.map((pool: any) => ({
        value: pool.id,
        label: pool.title,
        campaignId: pool.campaignId,
      }));
      setAvailableLinkedPools(formattedPools);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchLinkedPools();
  }, [fetchLinkedPools]);

  const handleSelectChange = (selected: any) => {
    setSelectedLinkedPool(selected);
    setPool(selected);
  };

  return (
    <>
      <ConfirmDialog
        title={"Block User"}
        open={isOpen}
        confirmLoading={loading}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpen(false);
        }}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Wallet</label>
          <input
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
            className={classes.formControlInput}
          />
        </div>

        <div className={classes.formControl}>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
                height: "50px",
              }),
              control: (provided) => ({
                ...provided,
                width: "100%",
                height: "50px",
              }),
              clearIndicator: (provided) => ({
                ...provided,
                color: "gray", // Customize the color of the clear icon
                cursor: "pointer",
                padding: "5px", // Add some padding for better clickability
              }),
            }}
            options={availableLinkedPools}
            placeholder="Select pool"
            value={selectedLinkedPool}
            onChange={handleSelectChange}
            isClearable

          />
        </div>
      </ConfirmDialog>
    </>
  );
}

export default SetBlockUsersForm;
