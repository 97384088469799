import React, { useEffect, useMemo, useState } from "react";
import useStyles from "../style";
import { isValidAddress } from "../../../services/web3";
import { renderErrorCreatePool } from "../../../utils/validate";
import { useCommonStyle } from "../../../styles";
import { etherscanRoute } from "../../../utils";
import Link from "@material-ui/core/Link";
import { NETWORK_AVAILABLE } from "../../../constants";
import { getReceiverWallets } from "../../../request/receiver-wallet";
import Select from "react-select";
import { Controller } from "react-hook-form";

interface SelectedWalletType {
  value: string;
  label: string;
}

function AddressReceiveMoney(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const {
    register,
    setValue,
    control,
    errors,
    needValidate,
    poolDetail,
    watch,
    isEditDisabled,
  } = props;
  const renderError = renderErrorCreatePool;
  const networkAvailable = watch("networkAvailable");
  const isSolanaPool =
    watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA ||
    networkAvailable === NETWORK_AVAILABLE.SOLANA;

  const [receiverWallets, setReceiverWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState<SelectedWalletType>();

  const isDeployed = !!poolDetail?.is_deploy;

  // To fetch all receiver wallets
  useEffect(() => {
    const fetchData = async () => {
      if (receiverWallets.length > 0) return;
      try {
        const response = await getReceiverWallets();
        if (response?.status === 200) {
          setReceiverWallets(response?.data);
        } else {
          console.log("error", response);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const filteredWallets: SelectedWalletType[] = useMemo(() => {
    if (!receiverWallets.length) return [];
    return receiverWallets
      .filter((wallet: any) => wallet.network === networkAvailable)
      .map((item: any) => ({ value: item?.wallet, label: item?.wallet }));
  }, [receiverWallets, networkAvailable]);

  // To sync form state with selectedWallet
  useEffect(
    () => setValue("addressReceiver", selectedWallet?.value),
    [selectedWallet, setValue]
  );

  // To set initial value of selectedWallet with form state
  useEffect(() => {
    if (poolDetail && poolDetail.address_receiver) {
      setSelectedWallet({
        value: poolDetail.address_receiver,
        label: poolDetail.address_receiver,
      });
    }
  }, [poolDetail, setSelectedWallet]);

  // To set default value
  useEffect(() => {
    if (networkAvailable === "tba") {
      setSelectedWallet({ value: "", label: "" });
    } else if (filteredWallets.length > 0) {
      // if (poolDetail && poolDetail.address_receiver) {
      //   setSelectedWallet({ value: poolDetail.address_receiver, label: poolDetail.address_receiver });
      // }else{
      //   setSelectedWallet({ value: "", label: "" });
      // }
      // setSelectedWallet(filteredWallets[0]);
    } else if (!isSolanaPool) {
      filteredWallets.push({
        value: "0xbA80Cb24185EF36deb1607Ab4CA17aC1389a6957",
        label: "0xbA80Cb24185EF36deb1607Ab4CA17aC1389a6957",
      });
      setSelectedWallet({
        value: "0xbA80Cb24185EF36deb1607Ab4CA17aC1389a6957",
        label: "0xbA80Cb24185EF36deb1607Ab4CA17aC1389a6957",
      });
    } else {
      setSelectedWallet({ value: "", label: "" });
    }
  }, [isSolanaPool, setValue, networkAvailable, filteredWallets]);

  const renderCurrentAddress = () => {
    if(!poolDetail.address_receiver) return
    return (
      <p style={{ marginTop: "10px" }} className={classes.formControlLabel}>
        Current address: {poolDetail?.address_receiver}
      </p>
    );
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>
          Address (Receive money)
        </label>
        {isDeployed && (
          <div className={commonStyle.boldText}>
            <Link
              href={
                isSolanaPool
                  ? `${etherscanRoute(
                      poolDetail?.address_receiver,
                      poolDetail
                    )}?cluster=${process.env.REACT_APP_SOLANA_NETWORK}`
                  : etherscanRoute(poolDetail?.address_receiver, poolDetail)
              }
              target={"_blank"}
            >
              {poolDetail?.address_receiver}
            </Link>
          </div>
        )}

        {!isDeployed && (
          <>
            <Controller
              name="addressReceiver"
              control={control}
              rules={{
                required: needValidate,
                validate: {
                  validAddress: (selected: any) => {
                    if (!needValidate) return true;
                    return isSolanaPool
                      ? true
                      : isValidAddress(selected) || "Invalid address";
                  },
                },
              }}
              render={(field) => {
                const walletOptions = [
                  { label: "Change Your Receiver Wallet", value: "" },
                  ...filteredWallets,
                ];

                return (
                  <Select
                    {...field}
                    isOptionDisabled={(option) => option.value === ""}
                    options={walletOptions}
                    value={
                      walletOptions.find(
                        (option) => option.value === selectedWallet?.value
                      ) || null
                    }
                    onChange={(selected: any) => {
                      console.log(selected);
                      setSelectedWallet(selected);
                      field.onChange(selected?.value || "");
                    }}
                    placeholder="Change Your Receiver Address"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: "100%",
                        height: "50px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        height: "50px",
                      }),
                    }}
                  />
                );
              }}
            />
            {renderCurrentAddress()}

            <p className={classes.formErrorMessage}>
              {renderError(errors, "addressReceiver")}
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default AddressReceiveMoney;
