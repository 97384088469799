import { Grid } from "@material-ui/core";
import {
  POOL_IS_PRIVATE,
  CUSTOM_NETWORK,
  TRANSFER_OWNERSHIP,
  SOLANA,
  NETWORK_AVAILABLE,
} from "../../../constants";
import AcceptCurrency from "../Components/AcceptCurrency";
import AddressReceiveMoney from "../Components/AddressReceiveMoney";
import AirdropNetwork from "../Components/AirdropNetwork";
import BuyType from "../Components/BuyType";
import DisplayPoolSwitch from "../Components/DisplayPoolSwitch";
import ExchangeRate from "../Components/ExchangeRate";
import ForbiddenCountry from "../Components/ForbiddenCountry/ForbiddenCountry";
import KycRequired from "../Components/KycRequired";
import NetworkAvailable from "../Components/NetworkAvailable";
import ParticipantNumber from "../Components/ParticipantNumber";
import PoolBanner from "../Components/PoolBanner";
import PoolDescription from "../Components/PoolDescription";
import PoolHash from "../Components/PoolHash";
import PoolClaimHash from "../Components/PoolClaimHash";
import PoolName from "../Components/PoolName";
import PoolWebsite from "../Components/PoolWebsite";
import PoolRelationship from "../Components/PoolRelationship";
import PoolMarketMaker from "../Components/PoolMarketMaker";
import PoolMarketMakerIcon from "../Components/PoolMarketMakerIcon";
import PrivatePoolSetting from "../Components/PrivatePoolSetting";
import SocialSetting from "../Components/SocialSetting/SocialSetting";
import TokenAddress from "../Components/TokenAddress";
import TokenLogo from "../Components/TokenLogo";
import TokenCoingeckoId from "../Components/TokenCoingeckoId";

import TokenSymbol from "../Components/TokenSymbol";
import TotalCoinSold from "../Components/TotalCoinSold";
import GleamRequirement from "../Components/WhitelistSocialRequirement/GleamRequirement";
import useStyles from "../style";
import ClaimNetwork from "../Components/ClaimNetwork";
import { useMemo, useState } from "react";
import ProgressDisplaySetting from "../Components/ProgressDisplaySetting/ProgressDisplaySetting";
import DepositRefundBalance from "../Components/DepositRefundBalance/DepositBalance";
import WithdrawRemainingToken from "../Components/WithdrawRemainingToken";
import WithdrawRefundBalance from "../Components/WithdrawRefundBalance/WithdrawBalance";
import ShortDescription from "../Components/ShortDescription";
import React from "react";
// import ATHPrice from "../Components/ATHPrice";
import StaticATHRoi from "../Components/StaticATHRoi";
import DepositToken from "../Components/DepositToken/DepositToken";
import TokenPriceGiveaway from "../Components/TokenPriceGiveaway";
import RefundTerms from "../Components/RefundTerms";
import TransferOwnership from "../Components/TransferOwnership";
import InitialMarketCapitilization from "../Components/InitialMarketCapitilization";
import TotalInitialMarketCapitilization from "../Components/TotalInitialMarketCapitilization";
import FullyDelutedValuationComponent from "../Components/FullyDelutedValuationComponent";
import TonDistribution from "../Components/TonDistribution";
import TonAddressToggle from "../Components/TonAddressToggle";
import TonInputBox from "../Components/TonInputBox";
import ClaimLink from "../Components/ClaimLink";
import ClaimLinkInput from "../Components/ClaimLinkInput";
import DepositCurrency from "../Components/DepositCurrency/DepositCurrency";
import HideContractAddress from "../Components/HideContractAddress";
import CustomNetworkLink from "../Components/CustomNetworkLink";
import CustomNetworkTitle from "../Components/CustomNetworkTitle";
import CustomNetworkIcon from "../Components/CustomNetworkIcon";
import PoolType from "../Components/PoolType";
import SolanaPoolHash from "../Components/SolanaPoolHash";
import SolanaTransferOwnership from "../Components/SolanaTransferOwnership";
import SolanaNetworkAvailable from "../Components/SolanaNetworkAvailable";
import SolanaClaimNetwork from "../Components/SolanaClaimNetwork";
import SolanaAcceptCurrency from "../Components/SolanaAcceptCurrency";
import SolanaDepositToken from "../Components/SolanaDepositToken/SolanaDepositToken";
import SolanaWithdrawRemainingToken from "../Components/SolanaWithdrawRemainingTokens";
import SolanaWithdrawRefundBalance from "../Components/SolanaWithdrawRefundBalance";
import SolanaDepositRefundBalance from "../Components/SolanaDepositRefundBalance/SolanaDepositBalance";
import LockPool from "../Components/LockPool";

const TabProjectInfo = (props: any) => {
  const classes = useStyles();

  const {
    showTab,
    poolDetail,
    setValue,
    errors,
    control,
    register,
    watch,
    token,
    setToken,
    getValues,
    needValidate,
    watchIsPrivate,
    getTokenInforDetail,
    handlerDeployClaimPool,
    isDeployed,
    isEdit,
  } = props;

  const poolType = watchIsPrivate ? Number(watchIsPrivate) : 0;
  const poolForCommunity =
    poolType === POOL_IS_PRIVATE.COMMUNITY ||
    poolType === POOL_IS_PRIVATE.EVENT;
  const availableNetwork = watch("networkAvailable");
  const claimNetwork = watch("networkClaim");
  const duoNetworkPool = useMemo(() => {
    return availableNetwork !== claimNetwork;
  }, [availableNetwork, claimNetwork]);

  const [pageReload, setPageReload] = useState(false);

  const isTonAddressVisible = watch("is_custom_token") === 1;
  const isClaimLinkVisible = watch("is_claim_link") === 1;
  const isTonDistributionVisable = watch("is_custom_network") === 1;
  const isSolanaPool = isDeployed
    ? availableNetwork === NETWORK_AVAILABLE.SOLANA
    : isEdit
    ? watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA
    : watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA;

  const displayPool = watch("is_display");

  return (
    <div style={{ display: showTab ? "inherit" : "none" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.exchangeRate}>
          {!!poolDetail?.id && (
            <DisplayPoolSwitch
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              setPageReload={setPageReload}
            />
          )}

            {!!poolDetail?.id && !displayPool && !pageReload && (
              <LockPool
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                watch={watch}
              />
            )}

          {SOLANA && (
            <PoolType
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
            />
          )}

          <PoolName
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
          />

          <PoolBanner
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            getValues={getValues}
          />

          <PoolWebsite
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
          />

          <PoolRelationship
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
          />

          <PoolMarketMaker
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
          />

          <PoolMarketMakerIcon
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
          />

          <RefundTerms
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
          />

          <TokenSymbol
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            watch={watch}
            errors={errors}
            getTokenInforDetail={getTokenInforDetail}
          />

          <TokenLogo
            poolDetail={poolDetail}
            register={register}
            errors={errors}
            setValue={setValue}
          />

          <TokenCoingeckoId
            poolDetail={poolDetail}
            register={register}
            errors={errors}
          />

          <TotalCoinSold
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />

          <InitialMarketCapitilization
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />

          <TotalInitialMarketCapitilization
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />

          <FullyDelutedValuationComponent
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />

          {watch("relationship_type") === "Giveaway" && (
            <TokenPriceGiveaway
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
            />
          )}
          {/* <ATHPrice
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
          /> */}
          <StaticATHRoi
            poolDetail={poolDetail}
            register={register}
            errors={errors}
          />

          {!!poolDetail?.is_deploy && (
            <ProgressDisplaySetting
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.exchangeRate}>
          <HideContractAddress
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />

          {isSolanaPool ? (
            <SolanaPoolHash
              poolDetail={poolDetail}
              duoNetworkPool={duoNetworkPool}
              watch={watch}
            />
          ) : (
            <PoolHash
              poolDetail={poolDetail}
              duoNetworkPool={duoNetworkPool}
              watch={watch}
            />
          )}
          {duoNetworkPool && (
            <PoolClaimHash
              poolDetail={poolDetail}
              handlerDeployClaimPool={handlerDeployClaimPool}
            />
          )}

          <TokenAddress
            poolDetail={poolDetail}
            register={register}
            token={token}
            setToken={setToken}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            watch={watch}
            needValidate={needValidate}
          />

          <AddressReceiveMoney
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            needValidate={needValidate}
            watch={watch}
            control={control}
          />

          <ExchangeRate
            poolDetail={poolDetail}
            register={register}
            token={token}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
            needValidate={needValidate}
          />
          {TRANSFER_OWNERSHIP && !isSolanaPool && (
            <TransferOwnership
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
            />
          )}
          {TRANSFER_OWNERSHIP && isSolanaPool && (
            <SolanaTransferOwnership
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              token={token}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.exchangeRate}>
          <BuyType
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
          />

          <PrivatePoolSetting
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
          />

          {/* <TokenType
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
          /> */}

          {isSolanaPool ? (
            <SolanaNetworkAvailable
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              needValidate={needValidate}
            />
          ) : (
            <NetworkAvailable
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              needValidate={needValidate}
              watch={watch}
            />
          )}

          {isSolanaPool ? (
            <SolanaClaimNetwork
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              needValidate={needValidate}
              watch={watch}
            />
          ) : (
            <ClaimNetwork
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              needValidate={needValidate}
              watch={watch}
            />
          )}
          {isSolanaPool ? (
            <SolanaAcceptCurrency
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
            />
          ) : (
            <AcceptCurrency
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
            />
          )}

          <AirdropNetwork
            poolDetail={poolDetail}
            setValue={setValue}
            control={control}
          />

          <KycRequired
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />

          {CUSTOM_NETWORK && (
            <label className={classes.exchangeRateTitle}>
              Custom Network Setting
            </label>
          )}
          <br />
          <br />

          {CUSTOM_NETWORK && (
            <TonDistribution
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              getValues={getValues}
              watch={watch}
            />
          )}

          {isTonDistributionVisable && CUSTOM_NETWORK && (
            <>
              <CustomNetworkTitle
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                getTokenInforDetail={getTokenInforDetail}
              />

              <CustomNetworkLink
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                getTokenInforDetail={getTokenInforDetail}
              />

              <CustomNetworkIcon
                poolDetail={poolDetail}
                register={register}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
              />
            </>
          )}

          {CUSTOM_NETWORK && (
            <TonAddressToggle
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
            />
          )}

          {isTonAddressVisible && (
            <TonInputBox
              poolDetail={poolDetail}
              register={register}
              token={token}
              setToken={setToken}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              needValidate={needValidate}
            />
          )}

          {CUSTOM_NETWORK && (
            <ClaimLink
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
            />
          )}

          {isClaimLinkVisible && CUSTOM_NETWORK && (
            <ClaimLinkInput
              poolDetail={poolDetail}
              register={register}
              token={token}
              setToken={setToken}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              needValidate={needValidate}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.exchangeRate}>
          <ForbiddenCountry
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />
          {isDeployed &&
            availableNetwork &&
            availableNetwork !== undefined &&
            availableNetwork !== "tba" &&
            (availableNetwork === NETWORK_AVAILABLE.BSC ||
              availableNetwork === NETWORK_AVAILABLE.ETH ||
              availableNetwork === NETWORK_AVAILABLE.POLYGON ||
              availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
              availableNetwork === NETWORK_AVAILABLE.BASE ||
              availableNetwork === NETWORK_AVAILABLE.DAO ||
              availableNetwork === NETWORK_AVAILABLE.OKX ||
              availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
              availableNetwork === NETWORK_AVAILABLE.LINEA ||
              availableNetwork === NETWORK_AVAILABLE.BLAST ||
              availableNetwork === NETWORK_AVAILABLE.BERA ||
              availableNetwork === NETWORK_AVAILABLE.SONIC ||
              availableNetwork === NETWORK_AVAILABLE.ARBITRUM) && (
              <>
                <DepositRefundBalance
                  poolDetail={poolDetail}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                />
                <WithdrawRefundBalance
                  poolDetail={poolDetail}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  watch={watch}
                />
                <WithdrawRemainingToken poolDetail={poolDetail} watch={watch} />
                {isDeployed &&
                  availableNetwork &&
                  availableNetwork !== undefined &&
                  availableNetwork !== "tba" &&
                  (availableNetwork === NETWORK_AVAILABLE.BSC ||
                    availableNetwork === NETWORK_AVAILABLE.ETH ||
                    availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                    availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                    availableNetwork === NETWORK_AVAILABLE.BASE ||
                    availableNetwork === NETWORK_AVAILABLE.DAO ||
                    availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                    availableNetwork === NETWORK_AVAILABLE.OKX ||
                    availableNetwork === NETWORK_AVAILABLE.LINEA ||
                    availableNetwork === NETWORK_AVAILABLE.BLAST ||
                    availableNetwork === NETWORK_AVAILABLE.BERA ||
                    availableNetwork === NETWORK_AVAILABLE.SONIC ||
                    availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
                  availableNetwork !== NETWORK_AVAILABLE.SOLANA && (
                    <DepositToken
                      poolDetail={poolDetail}
                      setValue={setValue}
                      register={register}
                      watch={watch}
                      errors={errors}
                      control={control}
                    />
                  )}

                {isDeployed &&
                  availableNetwork &&
                  availableNetwork !== undefined &&
                  availableNetwork !== "tba" &&
                  (availableNetwork === NETWORK_AVAILABLE.BSC ||
                    availableNetwork === NETWORK_AVAILABLE.ETH ||
                    availableNetwork === NETWORK_AVAILABLE.POLYGON ||
                    availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
                    availableNetwork === NETWORK_AVAILABLE.BASE ||
                    availableNetwork === NETWORK_AVAILABLE.DAO ||
                    availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
                    availableNetwork === NETWORK_AVAILABLE.OKX ||
                    availableNetwork === NETWORK_AVAILABLE.LINEA ||
                    availableNetwork === NETWORK_AVAILABLE.BLAST ||
                    availableNetwork === NETWORK_AVAILABLE.SONIC ||
                    availableNetwork === NETWORK_AVAILABLE.ARBITRUM) &&
                  availableNetwork !== NETWORK_AVAILABLE.SOLANA && (
                    <DepositCurrency
                      poolDetail={poolDetail}
                      setValue={setValue}
                      register={register}
                      watch={watch}
                      errors={errors}
                      control={control}
                    />
                  )}
              </>
            )}

          {isDeployed &&
            availableNetwork &&
            availableNetwork !== undefined &&
            availableNetwork !== "tba" &&
            (availableNetwork === NETWORK_AVAILABLE.BSC ||
              availableNetwork === NETWORK_AVAILABLE.ETH ||
              availableNetwork === NETWORK_AVAILABLE.POLYGON ||
              availableNetwork === NETWORK_AVAILABLE.LINEA ||
              availableNetwork === NETWORK_AVAILABLE.AVALANCHE ||
              availableNetwork === NETWORK_AVAILABLE.BASE ||
              availableNetwork === NETWORK_AVAILABLE.DAO ||
              availableNetwork === NETWORK_AVAILABLE.OKX ||
              availableNetwork === NETWORK_AVAILABLE.ZKSYNC ||
              availableNetwork === NETWORK_AVAILABLE.BLAST ||
              availableNetwork === NETWORK_AVAILABLE.SONIC ||
              availableNetwork === NETWORK_AVAILABLE.ARBITRUM) && (
              <WithdrawRemainingToken poolDetail={poolDetail} watch={watch} />
            )}

          {isDeployed &&
            availableNetwork &&
            availableNetwork === NETWORK_AVAILABLE.SOLANA && (
              <>
                {isDeployed && (
                  <SolanaDepositRefundBalance
                    poolDetail={poolDetail}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    watch={watch}
                  />
                )}
                {isDeployed && (
                  <SolanaWithdrawRefundBalance
                    poolDetail={poolDetail}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    watch={watch}
                  />
                )}

                {isDeployed && (
                  <SolanaWithdrawRemainingToken poolDetail={poolDetail} />
                )}
                {!!poolDetail?.is_deploy && (
                  <SolanaDepositToken
                    poolDetail={poolDetail}
                    setValue={setValue}
                    register={register}
                    watch={watch}
                    errors={errors}
                    control={control}
                  />
                )}
              </>
            )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.exchangeRate}>
          <SocialSetting
            poolDetail={poolDetail}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
            register={register}
          />

          {poolForCommunity && (
            <ParticipantNumber
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              control={control}
              watch={watch}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.exchangeRate}>
          <GleamRequirement
            poolDetail={poolDetail}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className=""></div>
        </Grid>

        <Grid item xs={6}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.exchangeRate}>
            <ShortDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
            />
            <PoolDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              header="Product"
              fieldName="description"
            />
            <PoolDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              header="Roadmap"
              fieldName="roadmap"
            />
            <PoolDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              header="Business model"
              fieldName="business_model"
            />
            <PoolDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              header="Team"
              fieldName="team"
            />
            <PoolDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              header="Partners and investors"
              fieldName="partners_investors"
            />
            <PoolDescription
              poolDetail={poolDetail}
              register={register}
              setValue={setValue}
              errors={errors}
              control={control}
              header="Token metrics"
              fieldName="token_metrics"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TabProjectInfo;
