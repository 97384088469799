export const moduleConfig = {
  WHITE_LIST_WALLET: {
    url: `edit-white-list-wallets/`,
    id: true,
  },
  RECEIVER_WALLET: {
    url: `edit-receiver-wallets/`,
    id: true,
  },
  LIST_USER: {
    url: `users`,
    id: false,
  },

  KYC_USERS: {
    url: `kyc-user-detail/`,
    id: true,
  },

  STAKING_POOL: {
    url: `staking/`,
    id: true,
  },

  LIST_ADMIN: {
    url: `admin-detail/`,
    id: true,
  },

  RPC_URLS: {
    url: `edit-rpc-url/`,
    id: true,
  },

  TIER_RANGE: {
    url: `edit-tiers-range/`,
    id: true,
  },
  CONFIG: {
    url: `config/`,
    id: false,
  },

  PARTICIPANTS: {
    url: `campaign-detail/`,
    id: true,
  },
  POOL: {
    url: `campaign-detail/`,
    id: true,
  },
  FCFS: {
    url: `campaign-detail/`,
    id: true,
  },
  WINNERS: {
    url: `campaign-detail/`,
    id: true,
  },
};

export type ModuleConfigType = keyof typeof moduleConfig;
