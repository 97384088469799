import React from "react";
import { Typography, Button } from "@material-ui/core";
import useStyles from "../style";
import { generateLink } from "../../../request/pool";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import GenerateLinkForm from "./GenerateLinkForm";
import Chip from "@material-ui/core/Chip"
import Icon from "@material-ui/core/Icon"
import { NETWORK_AVAILABLE } from "../../../constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const GenerateLink = (props: any) => {
  const classes = useStyles();
  const { register, errors, poolDetail, setValue } = props;
  console.log(poolDetail, "pool details");
  const [generatedLink, setGeneratedLink] = useState("");
  const [loadingLink, setLoadingLink] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);

  const handleGenerateLink = async () => {
    try {
      setLoadingLink(true);
      let regenerate = false;
      if (generatedLink) {
        regenerate = true;
      }
      const response = await generateLink(poolDetail.id, regenerate);
      const link = poolDetail?.network_available === NETWORK_AVAILABLE.SOLANA ? `${BASE_URL}/solana/buy-token/${poolDetail.id}/${response}` : `${BASE_URL}/buy-token/${poolDetail.id}/${response}`;
      setGeneratedLink(link);
      setLoadingLink(false);
    } catch (error) {
      console.error("Error generating link:", error);
    }
  };

  const openPopupCreate = (e: any) => {
    handleGenerateLink();
    setIsOpenEditPopup(true);
  };

  const handleCopyToClipboard = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div style={{ marginBottom: "10px", textAlign: 'right' }}>
      <GenerateLinkForm
        isOpenEditPopup={isOpenEditPopup}
        generatedLink={generatedLink}
        setIsOpenEditPopup={setIsOpenEditPopup}
        handleCopyToClipboard={handleCopyToClipboard}
        copied={copied}
        loadingLink={loadingLink}
      />
      <Chip
        icon={<Icon>share</Icon>}
        label="Share"
        onClick={openPopupCreate}
        variant="outlined"
        color="primary"
      />
    </div>
  );
};

export default GenerateLink;
