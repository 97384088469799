import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Button } from "@material-ui/core";
import { useCommonStyle } from "../../../../styles";
import {
  deleteAllWinnerUser,
  deleteWinnerUser,
  getWinnerLogs,
  getWinnerUser,
} from "../../../../request/participants";
import useGetList from "../hooks/useGetList";
import useDeleteItem from "../hooks/useDeleteItem";
import Pagination from "@material-ui/lab/Pagination";
import useStylesTable from "./style_table";
import { etherscanRoute } from "../../../../utils";
import Link from "@material-ui/core/Link";
import useMapMaxBuyTier from "../hooks/useMapMaxBuyTier";
import BigNumber from "bignumber.js";
import { POOL_IS_PRIVATE } from "../../../../constants";
import TableWinnerForm from "./TableWinnerForm";
import { useState } from "react";
import { alertFailure } from "../../../../store/actions/alert";
import { useDispatch } from "react-redux";
import TableWinnerAllForm from "./TableWinnerAllForm";

function TableWinnerLogs(props: any) {
  const commonStyle = useCommonStyle();
  const classesTable = useStylesTable();
  const { poolDetail } = props;
  const {
    rows,
    search,
    searchDelay,
    failure,
    loading,
    lastPage,
    currentPage,
    totalRecords,
    handlePaginationChange,
    status,
    setStatus,
  } = useGetList({ poolDetail, handleSearchFunction: getWinnerLogs });
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [password, setPassword] = useState("");

  const handlePassword = async (rowIndex: any) => {
    try {
      deleteItem(rowIndex.e, rowIndex.row, rowIndex.index);
      setIsOpenEditPopup(false);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleAllPassword = async () => {
    try {
      deleteAllItem();
      setOpenEditPopup(false);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const { deleteItem, deleteAllItem } = useDeleteItem({
    poolDetail,
    handleDeleteFunction: deleteWinnerUser,
    handleDeleteAllFunction: deleteAllWinnerUser,
    handleSearchFunction: search,
  });

  const { maxBuyTiersMapping, minBuyTiersMapping } = useMapMaxBuyTier({
    poolDetail,
  });

  const STATUS = ["false", "true"];

  return (
    <div className={commonStyle.boxSearch}>
      {isOpenEditPopup && (
        <TableWinnerForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          setPassword={setPassword}
          password={password}
          handlePassword={handlePassword}
        />
      )}
      {openEditPopup && (
        <TableWinnerAllForm
          openEditPopup={openEditPopup}
          setOpenEditPopup={setOpenEditPopup}
          setPassword={setPassword}
          password={password}
          handleAllPassword={handleAllPassword}
        />
      )}
      <input
        className={commonStyle.inputSearch}
        onChange={searchDelay}
        placeholder="Search"
      />
      <img src="/images/icon-search.svg" alt="" style={{ marginLeft: -30 }} />

      <TableContainer
        component={Paper}
        className={`${commonStyle.tableScroll} ${classesTable.tableUserJoin}`}
      >
        <Table className={classesTable.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Error Type</TableCell>
              <TableCell align="center">Wallet Address</TableCell>
              {poolDetail?.supported_token_types === "solana" && (
                <TableCell align="center">Solana Wallet Address</TableCell>
              )}
              <TableCell align="center">Level</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row: any, index: number) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.issue_type}
                  </TableCell>

                  <TableCell align="center">
                    <Link
                      href={etherscanRoute(row.wallet_address, poolDetail)}
                      target={"_blank"}
                    >
                      {row.wallet_address}
                    </Link>
                  </TableCell>
                  {poolDetail?.supported_token_types === "solana" && (
                    <TableCell align="center">
                      <Link
                        href={etherscanRoute(row.solana_address, poolDetail)}
                        target={"_blank"}
                      >
                        {row.solana_address}
                      </Link>
                    </TableCell>
                  )}

                  <TableCell align="center" component="th" scope="row">
                    {row?.level}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {new Date(row.created_at).toLocaleDateString() || 0}
                  </TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {failure && <p className={classesTable.errorMessage}>{failure}</p>}
        {!failure && (!rows || rows.length === 0) && !loading ? (
          <p className={classesTable.noDataMessage}>There is no data</p>
        ) : (
          <>
            {rows && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classesTable.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
    </div>
  );
}

export default TableWinnerLogs;
