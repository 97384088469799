import React, { useEffect } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import ImageUploader from "./ImageUploader";

function CustomNetworkIcon(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, setValue } = props;
  const renderError = renderErrorCreatePool;

  const handleFileSelect = (imageUrl: any) => {
    setValue("custom_network_icon", imageUrl); // Assuming you're using something like useForm from react-hook-form
  };

  useEffect(() => {
        register("custom_network_icon");
      }, [register]);

  return (
    <>
      <div className={classes.formControl}
      style={{
        marginBottom: "20px",
      }}
      >
        <label className={classes.formControlLabel}>Custom Network Icon</label>
        <input
          type="text"
          name="custom_network_icon"
          defaultValue={poolDetail?.custom_network_icon}
          ref={register({ required: true })}
          className={classes.formControlInput}
      
        />

        <p className={classes.formErrorMessage}>
          {renderError(errors, "custom_network_icon")}
        </p>
        <ImageUploader
          onFileSelect={handleFileSelect}
          width="50%"
          poolId={poolDetail?.id}
          value={poolDetail?.custom_network_icon}
        />
      </div>
    </>
  );
}

export default CustomNetworkIcon;
